import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import clone from "lodash/clone";
import flowRight from "lodash/flowRight";
import isEmpty from "lodash/isEmpty";
import merge from "lodash/merge";
import {FormattedMessage} from "react-intl";
import {Constants, ContactDirectDebitResponse, DirectDebit} from "@folksam-digital/model";
import {CmsContext, ICmsContext} from "../../cms";
import {HeaderLayout, LoaderLayout} from "../../components";
import FormattedMarkdown from "../../components/FormattedMarkdown";
import {container} from "../../inversify.config";
import {
    IDraftService,
    ISessionContextManager,
    ISigningService,
    IStorage,
    IUserService,
    StorageKeys
} from "../../services";
import {Types} from "../../Types";
import {DynamicTitle} from "../../components/general/DynamicTitle";
import withDataAnalytics from "../../components/general/withDataAnalytics";
import {IOnTriggerDataLayerEvent, SatelliteEvents, SatelliteEventTransactionTypes} from "../../analytics/new/BaseAnalytics";
import withCmsProvider from "../../components/general/withCmsProvider";
import {ILocaleContext} from "../../intl/LocaleContext";
import {withLocaleContext} from "../../components/journey/form/withLocaleContext";
import sanitizeField from "../../components/journey/form/input/helpers/sanitizeField";
import {DirectDebitValidator} from "../../model";
import {SigningProgressModal} from "./SigningProgressModal";
import {createEmptyPrefilledFieldList} from "./util/createEmptyPrefilledFieldList";
import {handleIditValidationErrors, IErrors} from "./util/handleIditValidationErrors";
import {SetUpFormView} from "./SetUpFormView";
import {SsnFormatter, UrlHelper} from "@folksam-digital/services";
import {CmsHelper} from "../../Helpers/cms/CmsHelper";
import {IError} from "../../model/ValidatorBase";
import {normalizeValue} from "../../Helpers/normalize";
import {PhoneNumberFormatter} from "../../Helpers/PhoneNumberFormatter";
import { getAnalytics, AnalyticsType } from "../../analytics/AnalyticsSwitch";
import {SigningInitModal} from "./SigningInitModal";

interface ILocationStateProps extends DirectDebit {

}

interface ISetUpFormProps extends RouteComponentProps<any, any, ILocationStateProps>, IOnTriggerDataLayerEvent {
    localeContext: ILocaleContext;
    viewContext: any;
}

interface ISetUpFormState {
    errors: {[key: string]: any};
    data: DirectDebit;
    prefilled: boolean;
    isLoading: boolean;
    signingStatus?: string;
    signingAlreadyInProgress?: boolean;
    autostartToken?: string;
    qrData?: string;
}

interface ISigningStatus {
    requestId: string;
    documentId: string;
    externalContactNumber: string;
    bankAccountExternalNumber?: string;
    status?: string;
}

class SetUpFormInternal extends React.Component<ISetUpFormProps, ISetUpFormState> {
    public static contextType = CmsContext;
    context!: ICmsContext;

    private userService: IUserService;
    private signingService: ISigningService;
    private emptyPrefilledFields: string[] = [];
    private componentUnmounted: boolean = false;
    private draftService: IDraftService;
    private sessionStorage: IStorage;
    private directDebitValidator: DirectDebitValidator;
    private sessionContextManager: ISessionContextManager;

    constructor(props: ISetUpFormProps, context: ICmsContext) {
        super(props, context);

        this.userService = container.get<IUserService>(Types.UserService);
        this.signingService = container.get<ISigningService>(Types.DirectDebitSigningService);
        this.draftService = container.get<IDraftService>(Types.DraftService);
        this.sessionStorage = container.get<IStorage>(Types.SessionStorage);
        this.directDebitValidator = container.get<DirectDebitValidator>(Types.DirectDebitValidator);
        this.sessionContextManager = container.get<ISessionContextManager>(Types.SessionContextManager);

        this.state = {
            errors: {},
            data: {} as DirectDebit,
            prefilled: false,
            isLoading: true,
            signingAlreadyInProgress: false
        };

        this.onHandleSubmit = this.onHandleSubmit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnChangeJustNumbersWrapper = this.handleOnChangeJustNumbersWrapper.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleTermsAndConditions = this.handleTermsAndConditions.bind(this);
        this.redirectToErrorPage = this.redirectToErrorPage.bind(this);
        this.onCloseSigning = this.onCloseSigning.bind(this);
        this.isEmptyPrefilledField = this.isEmptyPrefilledField.bind(this);
    }

    private get data(): DirectDebit {
        return this.draftService.getDraft<DirectDebit>(StorageKeys.DIRECT_DEBIT_FORM_DATA_KEY);
    }

    public async componentDidMount() {
        // Trigger Analytics Service Start
        window.addEventListener("load", () => {
            this.props.onTriggerDataLayerEvent!({
                messages: this.context.catalog.messages,
                journeyId: "directDebit",
                currentStep: "setUpForm",
                transactionType: SatelliteEventTransactionTypes.Application,
                event: SatelliteEvents.Start
            });
        });

        if (this.draftService.hasDraft(StorageKeys.DIRECT_DEBIT_FORM_DATA_KEY)) {
            const signingStatus = this.sessionStorage.get<ISigningStatus>(StorageKeys.DIRECT_DEBIT_SIGNING_STATUS_KEY) || {} as ISigningStatus;

            const mergedData = merge(clone(this.state.data), this.data) as DirectDebit;
            mergedData.failureReason = undefined;
            this.emptyPrefilledFields = createEmptyPrefilledFieldList(mergedData);
            this.setState({
                prefilled: true,
                isLoading: !!signingStatus?.status,
                signingStatus: signingStatus?.status,
                data: mergedData,
            });

            if (signingStatus?.status === Constants.Signing.statusCodes.internal.success.workflowCompleted) {
                await this.handleSigningCompleted();
                return;
            }

            await this.continueInitiatedSigningProcess(signingStatus);
            return;
        }

        this.setState({isLoading: false});
    }

    public isEmptyPrefilledField(name: string) {
        return this.emptyPrefilledFields.includes(name);
    }

    componentDidUpdate() {
        // Typical usage (don't forget to compare props):
        if (this.state?.errors?.fatalError) {
            this.redirectToErrorPage();
        }
    }

    public componentWillUnmount(): void {
        this.componentUnmounted = true;
    }

    public onCloseSigning() {
        this.setState({signingAlreadyInProgress: false});
    }

    public render(): React.ReactNode {
        return (
            <>
                <LoaderLayout isLoading={!!((!this.state.qrData && this.state.isLoading) || (this.state.signingStatus && this.state.signingStatus !== Constants.Signing.statusCodes.folksam.success.notStarted))}>
                    {this.getLoaderMessage()}
                </LoaderLayout>
                <DynamicTitle keyId={CmsHelper.withPrefix(this.context, "title.message")}/>
                <HeaderLayout
                    bannerImageName={CmsHelper.withPrefix(this.context, "bannerImage")}
                    journeyId={"directDebit"}
                    centered={true}
                    compact={true}
                    formData={{contact: {}}}
                    backUrl={Constants.Links.directDebitUrl}
                    stepBack={this.state.data.stepBack}
                    translations={{
                        header: CmsHelper.withPrefix(this.context, "banner.heading"),
                        subheader: CmsHelper.withPrefix(this.context, "banner.subheading"),
                        headerText: <FormattedMessage id={`${CmsHelper.withPrefix(this.context, "pageTitle.headerText")}`} />
                    }}
                />
                <SigningProgressModal
                    onClose={this.onCloseSigning}
                    signingAlreadyInProgress={this.state.signingAlreadyInProgress}
                    onSubmit={this.onHandleSubmit}
                    cmsContext={this.context}
                />
                <SigningInitModal
                    signingStatus={this.state.signingStatus}
                    cmsContext={this.context}
                    autostartToken={this.state.autostartToken}
                    qrData={this.state.qrData}
                />
                <SetUpFormView
                    data={this.state.data}
                    initialData={this.data}
                    onSubmit={this.onHandleSubmit}
                    errors={this.state.errors}
                    handleOnChange={this.handleOnChange}
                    handleOnBlur={this.handleOnBlur}
                    handleTermsAndConditions={this.handleTermsAndConditions}
                    isEmptyPrefilledField={this.isEmptyPrefilledField}
                    prefilled={this.state.prefilled}
                    disableFormSubmit={this.state.isLoading}
                />
            </>
        );
    }

    private getLoaderMessage(): React.ReactNode {
        return this.state.signingStatus || (this.state.signingStatus && this.state.signingStatus !== Constants.Signing.statusCodes.folksam.success.notStarted) ? <FormattedMarkdown
                messageKey={CmsHelper.withGeneralPrefix(`loader.signing.${this.state.signingStatus}`)}/> :
            <FormattedMessage id="general.loader.description">{msg => <span
                key={"loaderInnerMessage"}>{msg}</span>}</FormattedMessage>;
    }

    private async isContactEligibleForDirectDebit(details: ContactDirectDebitResponse): Promise<boolean> {
        try {
            if (!details) {
                this.redirectToErrorPage();
                return false;
            }

            if (!details.isFlaggedForDirectDebit && (details.areAllBankAccountsEmpty || details.areAllMandatesCancelled)) {
                return true;
            }

            return !!details.bankAccountExtNumber && !details.isFlaggedForDirectDebit && details.isLatestMandateSignable;
        } catch (e) {
            return false;
        }
    }

    private redirectToErrorPage() {
        this.props.history.push(UrlHelper.getUrl(["direct-debit", "error"]));
    }

    private handleOnChangeJustNumbersWrapper(event: any) {
        const {value} = event.target;
        event.target.value = value.replace(/\D/g, "");
        this.handleOnChange(event);
    }

    private handleOnChange(event: any) {
        const {id, value} = event.target;
        const data = merge(this.state.data, {[id]: sanitizeField(value)});
        this.setState({data});
    }

    private handleOnBlur(event: any) {
        const {id} = event.target;
        let fieldValue = this.state.data[id as keyof DirectDebit];

        if (typeof fieldValue === "string") {
            fieldValue = sanitizeField(fieldValue.trim());

            if (id === "phoneNumber") {
                fieldValue = normalizeValue(PhoneNumberFormatter.removeSpecialChars, fieldValue);
            }
        }

        const data = merge(this.state.data, {[id]: fieldValue});
        const fieldError = this.directDebitValidator.validateField(id, fieldValue);
        const errors = this.mergeErrors(fieldError, id);

        this.setState({data, errors});
    }

    private mergeErrors(fieldError: IError, fieldId: string): IError {
        let errors = this.state.errors || {};

        if (isEmpty(fieldError)) {
            Object.keys(errors).forEach(key => {
                if (key === fieldId) {
                    delete errors[key];
                    return;
                }
            });
        } else {
            errors = merge({}, errors, fieldError);
        }

        return errors;
    }

    private async handleTermsAndConditions(event: any) {
        const termsAndConditions = event.target.checked;
        const id = event.target.id;

        this.setState(({data}: ISetUpFormState) => {
            data.termsAndConditions = termsAndConditions;

            return {data};
        });

        const fieldError = this.directDebitValidator.validateField(id as string, termsAndConditions);
        const errors = this.mergeErrors(fieldError, id);

        this.setState({errors});
    }

    private async onHandleSubmit(event: any): Promise<void> {
        event.preventDefault();

        this.setState({isLoading: true, errors: {}, signingAlreadyInProgress: false});

        const errors = this.directDebitValidator.validate(this.state.data);
        const {data} = this.state;

        if (isEmpty(errors.ssn)) {
            data.ssn = this.directDebitValidator.removeDashFromSsn(data.ssn);
        }

        if (isEmpty(errors.phoneNumber)) {
            data.phoneNumber = this.directDebitValidator.removeSpecialCharsFromPhoneNumber(data.phoneNumber);
        }

        if (isEmpty(errors)) {
            data.ssn = SsnFormatter.convertFormat(data.ssn, true);
            this.setState({data});

            try {
                // Update Draft data
                this.draftService.updateDraft(data, StorageKeys.DIRECT_DEBIT_FORM_DATA_KEY);
                const directDebitDetails: ContactDirectDebitResponse | void = await this.userService.getContactDirectDebitDetails(data.ssn!);
                if (!directDebitDetails) {
                    return;
                }
                data.firstName = directDebitDetails?.firstName;
                data.lastName = directDebitDetails?.lastName;
                this.setState({data});
                this.draftService.updateDraft(data, StorageKeys.DIRECT_DEBIT_FORM_DATA_KEY);

                const isEligible = await this.isContactEligibleForDirectDebit(directDebitDetails);
                if (!isEligible) {
                    this.redirectToErrorPage();
                    return;
                }

                const directDebitValidation = await this.validateDirectDebitMandate(data, directDebitDetails);
                if (directDebitValidation.isValid) {
                    // Create dummy mandate
                    if (directDebitDetails.shouldCreateDummyMandate) {
                        await this.createDummyMandate(directDebitDetails);
                    }

                    // Sign mandate
                    const signed = await this.signMandate(directDebitDetails, data);

                    if (signed) {
                        this.removeSigningData();
                        await this.handleSigningCompleted();
                    }
                } else {
                    if (directDebitValidation?.errors?.fatalError) {
                        this.redirectToErrorPage();
                        return;
                    }

                    this.setState({errors: directDebitValidation.errors, isLoading: false});
                }
            } catch (err) {
                this.redirectToErrorPage();
            }
        } else {
            this.setState(() => {
                const dataToUpdate = {errors, isLoading: false} as unknown as ISetUpFormState;
                dataToUpdate.data = data;

                return dataToUpdate;
            });
        }
    }

    /**
     * Sign the mandate
     */
    private async signMandate(details: ContactDirectDebitResponse, data: DirectDebit): Promise<boolean> {
        try {
            if (!data.createNewMandate) {
                data.createNewMandate = details.shouldCreateDummyMandate;
            }

            // Init signing
            const signingRequest = await this.signingService.initDirectDebitSigning({
                contact: {
                    ssn: data.ssn!,
                    firstName: data.firstName!,
                    lastName: data.lastName!,
                    externalContactNumber: details.externalContactNumber,
                },
                bankAccount: {
                    clearingNumber: data.clearingNumber!,
                    accountNumber: data.accountNumber!,
                },
                data,
                transactionId: this.sessionContextManager.getSessionContext()?.transactionId,
                locale: this.props.localeContext.locale
            });

            this.setState({
                qrData: signingRequest?.qrData,
                autostartToken: signingRequest?.autostartToken
            });

            const signingStatus: ISigningStatus = {
                requestId: signingRequest.requestId,
                documentId: signingRequest.documentId,
                externalContactNumber: details.externalContactNumber,
                bankAccountExternalNumber: details?.bankAccountExtNumber
            };

            return await this.getSigningCompletionStatus(signingStatus);
        } catch (e: any) {
            if (e?.response?.data?.faultCode === Constants.Signing.statusCodes.internal.fault.inProgress) {
                this.setState({
                    signingAlreadyInProgress: true,
                    isLoading: false,
                });
            } else {
                this.redirectToSigningErrorPage();
            }
            return false;
        }
    }

    private async getSigningCompletionStatus(signingStatus: ISigningStatus): Promise<boolean> {
        // Await for updates until Success or Failure is retrieved
        const onStatusUpdate = ((status?: string, qrData?: string) => {
            this.setState({
                signingStatus: status, // Set signing message
                qrData
            });
            this.sessionStorage.set(StorageKeys.DIRECT_DEBIT_SIGNING_STATUS_KEY, {
                status,
                requestId: signingStatus.requestId,
                documentId: signingStatus.documentId,
                externalContactNumber: signingStatus.externalContactNumber,
                bankAccountExternalNumber: signingStatus.bankAccountExternalNumber
            } as ISigningStatus);
        });

        const isSigningCancelled = () => {
            return this.componentUnmounted;
        };

        let success = false;
        if (!isSigningCancelled()) {
            success = await this.signingService.awaitSigningComplete(signingStatus.requestId, isSigningCancelled, onStatusUpdate);
        }

        if (success) {
            const data = this.state.data;
            data.documentId = signingStatus.documentId;
            await this.promisedSetState(data);
        } else {
            if (this.state.signingStatus === Constants.Signing.statusCodes.internal.fault.mandateAlreadySigned) {
                const draftData: DirectDebit = this.draftService.getDraft<DirectDebit>(StorageKeys.DIRECT_DEBIT_FORM_DATA_KEY);
                draftData.failureReason = Constants.Signing.statusCodes.internal.fault.mandateAlreadySigned;
                this.draftService.updateDraft(draftData, StorageKeys.DIRECT_DEBIT_FORM_DATA_KEY);

                this.removeSigningData();
                this.redirectToErrorPage();
            }

            this.removeSigningStatus();
            this.redirectToSigningErrorPage();
        }

        return success;
    }

    private removeSigningStatus() {
        this.sessionStorage.remove(StorageKeys.DIRECT_DEBIT_SIGNING_STATUS_KEY);
        this.setState({
            signingStatus: undefined
        });
    }

    private removeSigningData() {
        const signingData = this.sessionStorage.get<ISigningStatus>(StorageKeys.DIRECT_DEBIT_SIGNING_STATUS_KEY) || {} as ISigningStatus;
        this.sessionStorage.set(StorageKeys.DIRECT_DEBIT_SIGNING_STATUS_KEY, {status: signingData?.status});
    }

    private redirectToSigningErrorPage() {
        if (this.componentUnmounted) {
            // skip navigating user to error page if component is unmounted(e.g. when component unmount due to navigating back with back button in browser) before Error
            return;
        }

        this.props.history.push(UrlHelper.getUrl(["direct-debit", "signing-error"]));
    }

    private async continueInitiatedSigningProcess(signingStatus: ISigningStatus): Promise<void> {
        if (signingStatus?.status) {
            const success = await this.getSigningCompletionStatus(signingStatus);

            if (success) {
                this.removeSigningData();
                await this.handleSigningCompleted();
            }
        }
    }

    private async handleSigningCompleted(): Promise<void> {
        try {
            this.props.history.push(UrlHelper.getUrl(["direct-debit", "success"]));
        } catch (err) {
            this.redirectToErrorPage();
            return;
        }
    }

    private async createDummyMandate(details: ContactDirectDebitResponse): Promise<void> {
        const {data} = this.state;
        data.customerExtId = details.externalContactNumber;

        const responseData = await this.userService.saveDummyDirectDebitMandate(data);

        if (!responseData || (typeof responseData === "object" && responseData.type)) {
            throw new Error("Mandate signing has failed with an error");
        }
        data.bankAccountExternalNumber = String(responseData);

        this.setState({data});
    }

    private promisedSetState(data: DirectDebit): Promise<void> {
        return new Promise((resolve) => {
            this.setState({data}, () => {
                resolve();
            });
        });
    }

    private async validateDirectDebitMandate(data: DirectDebit, directDebitDetails: ContactDirectDebitResponse): Promise<{isValid: boolean, errors?: any}> {
        let errors: IErrors = {};
        let response;
        try {
            if (!directDebitDetails.shouldCreateDummyMandate) {
                data.bankAccountExternalNumber = directDebitDetails?.bankAccountExtNumber;
            }

            response = await this.userService.validateDirectDebitMandate(data);

            if (!response.isValid && response?.error?.type) {
                errors = handleIditValidationErrors(response.error, this.context);
            }
        } catch (error) {
            errors.fatalError = "404";
        }

        return {isValid: isEmpty(errors), errors};
    }
}

const SetUpForm = flowRight(
    withCmsProvider("directDebit"),
    withDataAnalytics(getAnalytics(AnalyticsType.DirectDebit)),
    withRouter,
    withLocaleContext,
)(SetUpFormInternal);

export default SetUpForm;
