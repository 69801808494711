import {IInitParams, StorageKeys} from "../../services";
import {extractQueryParams} from "../../Helpers/extractQueryParams";
import {UrlHelper} from "@folksam-digital/services";
import {IToggleProps} from "./featureToggle/withToggle";
import React, {useState} from "react";
import {useLocation} from "react-router";

const initParams = ({sessionStorage, location}: any): void => {
    let params: IInitParams = sessionStorage.get(StorageKeys.PARAMS) || {};
    if (location.search) {
        params = extractQueryParams(["workFlowEnv", "agentId", "dynamicBackUrl", "completionQueryString"], location.search) as IInitParams;
    }

    if (params?.dynamicBackUrl && !UrlHelper.isValidDynamicBackUrl(params?.dynamicBackUrl)) {
        delete params.dynamicBackUrl;
    }

    if (params?.completionQueryString && !UrlHelper.isValidCompletionQueryString(params?.completionQueryString)) {
        delete params.completionQueryString;
    }

    sessionStorage.set(StorageKeys.PARAMS, params);
}

const withClaimsInitParams = <P extends IToggleProps>(dependencies: any) => {
    return (WrappedComponent: React.ComponentType<P>) => (props: P) => {
        const location = useLocation();
        useState(() => {
            initParams({...dependencies, location});
            return true;
        });

        return <WrappedComponent {...props}/>;
    }
};

export {withClaimsInitParams};
